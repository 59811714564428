.alerts {
  border-radius: 20px !important;
}

.btn-pagination:hover {
  background: var(--primary-bg-color) !important;
}

.btn-content-scroll-up {
  width: 100%;
  height: 40px;
  top: 0%;
  position: sticky !important;
  display: flex;
  justify-content: center;
}

.btn-content-scroll-dowm {
  width: 100%;
  height: 40px;
  position: sticky !important;
  top: 84%;
  display: flex;
  justify-content: center;
}

.btn-scroll-up {
  color: #fff !important;
  width: 40px;
  height: 40px;
  background-color: hsl(0, 0%, 100%, 0.05) !important;
}

.btn-scroll-dowm {
  color: #fff !important;
  width: 40px;
  height: 40px;
  background-color: hsl(0, 0%, 100%, 0.05) !important;
}

.collapse-card {
  box-shadow: inset 0px 0px 25px -5px rgba(0, 0, 0, 0.4);
}

.card-button {
  border: 1px solid #484863;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
}

.card-button:hover {
  transform: scale(1.03);
}

.card-button-active {
  border-color: 1px solid #fff;
  transform: scale(1.03);
}

.drop-area {
  height: 300px;
  border: 2px dashed #a3a3a3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-area.active {
  background-color: #5e6375 !important;
  border: 2px dashed var(--primary-bg-color);
}

.loader-logo img {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-name: loading;
}

.ModalUpdate {
  border-radius: 20px;
  width: 450px;
  margin: auto;
}

.ModalConfirmUpdate {
  border-radius: 20px;
  width: 500px;
  margin: auto;
}

.profile-edit {
  position: absolute;
  left: 157px;
  bottom: 75px;
  color: #000;
  cursor: pointer;
  transition: 0.5s;
}

.profile-edit:hover {
  transform: rotate(-45deg);
}

.terms {
  border: 1px solid #fff;
  max-height: 500px;
  overflow: auto;
  padding: 10px;
  text-align: justify;
}

.textDark {
  color: black !important;
}

.transition-appear {
  animation-iteration-count: initial;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-name: appear;
}

#tsparticles {
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

/* Styles Brand Select */
.formselect__control {
  border-color: #484863 !important;
  background-color: transparent !important;
}

.formselect__control--is-disabled {
  background-color: #383d52 !important;
  opacity: 0.5;
}

.formselect__single-value {
  color: #fff !important;
}

/* Keyframes */
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: rotateY(360deg);
  }
}
.box-list-register {
  overflow-x: inherit !important;
}
